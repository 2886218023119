import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'target_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="target_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M14 2a1 1 0 011 1l.001 1.05c4.724.469 8.48 4.226 8.95 8.95H25a1 1 0 010 2l-1.05.001a10.003 10.003 0 01-8.949 8.95L15 25a1 1 0 01-2 0v-1.05a10.003 10.003 0 01-8.95-8.949L3 15a1 1 0 010-2h1.05A10.003 10.003 0 0113 4.05V3a1 1 0 011-1zm1.001 4.062L15 7a1 1 0 01-2 0v-.938A8.004 8.004 0 006.062 13H7a1 1 0 010 2l-.938.001A8.004 8.004 0 0013 21.938V21a1 1 0 012 0l.001.938a8.004 8.004 0 006.937-6.937L21 15a1 1 0 010-2h.938a8.004 8.004 0 00-6.937-6.938zM14 12a2 2 0 110 4 2 2 0 010-4z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TargetOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TargetOutline: FC<Icon28TargetOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TargetOutline as any).mountIcon = mountIcon;

export default Icon28TargetOutline;
