import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'like_outline_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="like_outline_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z" /><path d="M10.96 2.5A4.04 4.04 0 0115 6.54c0 2.214-.884 3.32-4.52 6.149l-1.099.854a2.25 2.25 0 01-2.762 0L5.52 12.69C1.884 9.86 1 8.754 1 6.539A4.04 4.04 0 015.04 2.5c1.111 0 2.103.482 2.96 1.404.857-.922 1.849-1.404 2.96-1.404zM5.04 4A2.54 2.54 0 002.5 6.54c0 1.59.653 2.407 3.94 4.965l1.1.854c.27.21.65.21.92 0l1.1-.854c3.287-2.558 3.94-3.375 3.94-4.966A2.54 2.54 0 0010.96 4c-.848 0-1.618.482-2.343 1.53L8 6.423l-.617-.893C6.658 4.482 5.888 4 5.04 4z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16LikeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16LikeOutline: FC<Icon16LikeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16LikeOutline as any).mountIcon = mountIcon;

export default Icon16LikeOutline;
