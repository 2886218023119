import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'share_outline_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="share_outline_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M11.996 3.725A2.15 2.15 0 0010 5.87l-.001 2.117-.02.005a9.904 9.904 0 00-7.827 10.721c.083.811 1.116 1.103 1.611.455l.187-.237a9.082 9.082 0 015.836-3.265l.213-.026.001 2.494a2.15 2.15 0 003.476 1.692l7.824-6.132a2.15 2.15 0 000-3.384l-7.824-6.132a2.15 2.15 0 00-1.326-.458zm.154 1.795a.35.35 0 01.216.075l7.824 6.132a.35.35 0 010 .55l-7.824 6.133a.35.35 0 01-.566-.276l-.001-3.447a.9.9 0 00-.915-.9l-.233.004-.342.017a10.88 10.88 0 00-6.119 2.365l-.174.144.024-.135a8.103 8.103 0 016.968-6.537.9.9 0 00.791-.893L11.8 5.87a.35.35 0 01.35-.35z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ShareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ShareOutline: FC<Icon24ShareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ShareOutline as any).mountIcon = mountIcon;

export default Icon24ShareOutline;
