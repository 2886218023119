import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'clip_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="clip_outline_28"><g fill="none"><path d="M0 0h28v28H0z" /><path d="M16.072 2.081a3.39 3.39 0 012.629 3.758l-.572 4.288a6.005 6.005 0 014.022 2.773 1 1 0 01-1.71 1.036A4.016 4.016 0 0017 12.02h-.016l-.047-.002-.054-.005-.015-.002-.053-.008a.995.995 0 01-.051-.011l.104.02a1.008 1.008 0 01-.656-.376 1.039 1.039 0 01-.176-.349.993.993 0 01-.036-.266v-.015c0-.015 0-.03.002-.046L16 11.02c0-.042.003-.084.008-.125v-.007l.71-5.314a1.395 1.395 0 00-2.746-.476l-.99 4.616a.998.998 0 01-.523.696l-.01.005a1.002 1.002 0 01-.178.069l-.028.007a1.007 1.007 0 01-.43.013l-.022-.005a.998.998 0 01-.48-.253l-.012-.011a1 1 0 01-.27-.47L9.986 5.593a1.404 1.404 0 00-2.762.462l.507 5.823c.293-.069.595-.115.904-.135l1.236-.083a3.405 3.405 0 01.787 6.755l-.493.082a1 1 0 11-.328-1.97l.493-.082a1.407 1.407 0 00-.326-2.792l-1.236.082A3.497 3.497 0 005.5 17.222v.29c0 3.585 2.91 6.49 6.5 6.49h.5a1 1 0 110 1.998H12c-4.694 0-8.5-3.8-8.5-8.488v-.29c0-1.833.902-3.467 2.299-4.466l-.568-6.528a3.402 3.402 0 013.392-3.694 3.404 3.404 0 013.303 2.575l-.001-.003.092-.426a3.395 3.395 0 014.055-2.599zm-.572 14.94c0-1.543 1.668-2.506 3-1.732l6.004 3.487c1.328.771 1.328 2.693 0 3.464L18.5 25.727c-1.331.774-2.999-.19-2.999-1.732zm2-.002v6.978l5.998-3.484h.001l.001-.005v-.003l-.001-.003z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ClipOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ClipOutline: FC<Icon28ClipOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ClipOutline as any).mountIcon = mountIcon;

export default Icon28ClipOutline;
